<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">

    <booking-form-pop-up :bookingFormPopUpActive.sync="bookingFormPopUpActive" :currentBooking.sync="currentBooking" @updateCurrentBooking="currentBooking = $event" :isEdit="isBookingListEdit" :startDate="startDate" :endDate="endDate"/>   

    <booking-preview ref="preview" :bookingPreviewActive.sync="popupActivo" :currentBooking.sync="currentBooking" @updateCurrentBooking="currentBooking = $event" @openEditBookingPopUp="editData" />

    <booking-confirmation :bookingConfirmationPopUp.sync="bookingConfirmationPopUp" :currentBooking.sync="currentBooking" @updateCurrentBooking="currentBooking = $event" @openPreview="popupActivo = true" :bookingConfirmationType="bookingConfirmationType"/>

    <vs-prompt :title="$t('calendar.bookingBlock')" class="export-options" color="danger" @accept="deleteBookingBlock" :accept-text="$t('delete')" :cancel-text="$t('close')" :active.sync="bookingBlockPromptActive">
      <p>{{$t('calendar.doYouWantToRemoveBookingBlock')}}</p>
    </vs-prompt>

    <vs-table 
      :sst="true" 
      ref="table" 
      v-model="selected" 
      pagination
      search 
      :max-items="parseInt(pages? pages.per_page: 1)" 
      :total="parseInt(pages? pages.total: 1)" 
      :data="bookingsItems"
      @change-page="handleChangePage"
      @search="handleSearch"
      @sort="sortBookings"
      @selected="showData"
      >


      
      <template slot="header">
        <div class="mb-5">
          <vs-select
            v-model="bookingFilters.event_date_type"
            >
            <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="item,index in [{text: $t('statisticsCards.filters.bookingDateTypeSelect.bookingDate'), value: 'created_at'}, {text: $t('statisticsCards.filters.bookingDateTypeSelect.eventDate'), value: 'start_date'}]" />
          </vs-select>
        </div>
      </template>

      <template slot="thead">
        <template>
          <vs-th v-for="(col, index) in generalInfoColumnsTitles" :key="index" :sort-key="col.sortKey">
            {{ col.title }}
          </vs-th>
        </template>
      </template>

      

      
      <template slot-scope="{data}">
        <tbody>
          <template>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

              <vs-td>
                <vx-tooltip :text="tr.source_description">
                  <vs-chip class="product-no font-medium truncate" :color="tr.source_color">{{ tr.source }}</vs-chip>
                </vx-tooltip>
              </vs-td>

              <vs-td>
                <p class="product-no font-medium truncate">{{ tr.state_id == 8 ? '' : tr.booking_number }}</p>
              </vs-td>

              <vs-td>
                <p class="product-name font-medium truncate">{{ tr.state_id == 8 ? 'حجب الحجوزات' : tr.customer_name }}</p>
              </vs-td>
              

              <vs-td>
                <vx-tooltip :text="tr.state.description" >
                  <vs-chip :color="tr.state.color" class="product-order-status">{{ tr.state.display_name}}</vs-chip>
                </vx-tooltip>
              </vs-td>

              <vs-td>
                <vx-tooltip :text="tr.created_at" >
                  <p class="booking-created">
                    {{ tr.created_at | moment("DD-MM-YYYY") }}
                  </p>
                </vx-tooltip>
              </vs-td>

              <vs-td>
                <vx-tooltip :text="tr.start_date" >
                  <p class="booking-date">
                    {{ tr.start_date | moment("DD-MM-YYYY")}}
                  </p>
                </vx-tooltip>
              </vs-td>

            </vs-tr>
          </template>
          
        </tbody>
      </template>
      
    </vs-table>

    
  </div>
</template>

<script>
import moment from 'moment'
import BookingFormPopUp from './BookingFormPopUp'
import BookingConfirmation from '@/views/q-pages/Booking/BookingConfirmation'
export default {
  components: {
    BookingFormPopUp,
    BookingConfirmation
  },
  data () {
    return {
      generalInfoColumnsTitles : [        
        {title: this.$t('bookingList.booked_by'), key: "source"},
        {title: this.$t('bookingList.no'), key: "booking_no"},
        {title: this.$t('bookingList.name'), key: "customer_name"},
        {title: this.$t('bookingList.category'), key: ""},
        {title: this.$t('bookingList.booking_at'), key: "created_at", sortKey: "created_at"},
        {title: this.$t('bookingUpdatesList.start_date'), key: "start_date", sortKey: "start_date"}
      ],
      page: 1,
      dateFormat: {
        input: 'YYYY-MM-DD'
      },
      sortKey: null,
      sortType: null,
      bookingsDateRange: {
        start: moment().startOf('month').toDate(),
        end: moment().endOf('month').toDate(),
      },
      selected: [],
      itemsPerPage: 20,

      // Data Sidebar
      isBookingListEdit: false ,
      popupActivo: false,
      currentBooking: {
        rooms: []        
      },
      popupActivo: false,

      bookingConfirmationPopUp: false,

      bookingFormPopUpActive: false,
      startDate: new Date(),
      endDate: new Date(),
      currentBooking: null,
      bookingConfirmationType: 'bookingConfirmation',
      bookingBlockPromptActive: false,


      bookingFilters: {
        event_date_type: 'created_at'
      }
    }
  },
  computed: {
    currentVenueId () {
        return this.$store.getters.currentVenueId
    },
    bookings () {
      return this.$store.state.dataList.bookings
    },
    bookingStates () {
      return this.$store.state.dataList.bookingStates
    },
    bookingsItems () { // an array for organizing the data of the booking
      let bookingsToEdit = JSON.parse(JSON.stringify(this.bookings))
      return bookingsToEdit.filter((booking) => {
        if(booking.source == "Admin"){
          booking.source = "Admin"
          booking.source_color = "#3B3B3B"
          booking.source_description = "Booking added by Eventful operating team (admins)"          
        }else if (booking.source == "App" || booking.source == null){
          booking.source = "Eventful App"
          booking.source_color = "#FFB113"
          booking.source_description = "Booking added by a customer using Eventful App"
        }else if (booking.source == "Web App"){
          booking.source = "Web App"
          booking.source_color = "#26029E"
          booking.source_description = "Booking added by a customer using Eventful Web App"
        }else if (booking.source == "SaaS"){
          booking.source = "SaaS"
          booking.source_color = "#6a0dad"
          booking.source_description = "Booking added by the Saas"
        }else if (booking.source == "Agent"){
          booking.source = "Agent"
          booking.source_color = "#10163A"
          booking.source_description = "Booking Added By Agent User (You)"
        }
        if(booking.state.name != 'booking_check_accepted')
          return booking
      })
    },
    pages () {
      return this.$store.state.dataList.pages
    },
    bookingOptionsList () {
      return this.$store.state.dataList.bookingOptionsList
    },
    bookingConfirmationStates() {
        return this.$store.state.bookingStates.bookingConfirmationStates;
    },
    bookingConfirmationStatesNames() {
        return this.bookingConfirmationStates.map(state => state.name);
    }
  },
  methods: {
    showData (data) {
      if(data.cancelation_reason == null)
        data.cancelation_reason = 'unavailable_date';
      this.currentBooking = data;
      let clickedState = data.state.name;
      if(this.currentBooking.state_id == 8 || this.currentBooking.state.name == 'booking_check_rejected') { // check if booking block
        this.bookingBlockPromptActive = true
      } else if(this.bookingConfirmationStatesNames && this.bookingConfirmationStatesNames.includes(clickedState)) {
        this.bookingConfirmationType = clickedState == 'booking_check' ? 'bookingCheck' : 'bookingConfirmation';
        this.bookingConfirmationPopUp = true;
      } else {
        this.popupActivo = true;
      }
    },
    deleteBookingBlock() {
      this.$store.dispatch('loader/loaderOn')
      this.$store.dispatch('dataList/deleteBookingBlock', this.currentBooking)
      .then(response=>{ 
          this.$vs.notify({
              color:'success',
              title: this.$t('deleteDone'),
              text: response.success_message || 'تم حذف حجب الحجوزات بنجاح'
          })
        })
        .catch(error => {
          this.$vs.notify({
              color:'danger',
              title: this.$t('error'),
              text: error || 'عذرًا لم نستطع تعديل البينات لوجود خطأ ما، اذا تكرر الخطأ يُرجى التواصل مع الادارة، وشكرًا'
          })
        })
        .finally(()=>{this.$store.dispatch('loader/loaderOff')},this);
    },
    closePopup () {
      this.popupActivo = false;
      this.bookingFormPopUpActive = false;
    },
    editData (data) {
      this.isBookingListEdit = true;
      this.bookingFormPopUpActive = true;
    },
    handleChangePage (page){
      this.page = page
      this.getBookings()
    },
    handleSearch (query) {
      const venueId = this.currentVenueId
      if(query == ""){
        this.$store.dispatch('dataList/fetchDataListItems', {venueId, page:1})
        return ;
      }
      this.$store.dispatch('dataList/search', {venueId, query})
    },
    sortBookings(sortKey,sortType) {
      this.sortKey = sortKey
      this.sortType = sortType
      this.getBookings()
    },
    getBookings() {
      const venueId = this.currentVenueId
      const sortKey = this.sortKey
      const sortType = this.sortType
      const page = this.page
      var states = null
      var startDate = null
      var endDate = null

      this.$store.dispatch('loader/loaderOn')
      this.$store.dispatch('dataList/fetchDataListItems', {venueId, page:page, sortKey, sortType, startDate, endDate, states, getAllPages: false})
      .then((response) => {

      },this)
      .catch((error) => {
        console.log(error.response)
        console.log("errrr", error)
        this.$vs.notify({
                color:'danger',
                title: this.$t('error'),
                text:error.response.data.error_message
              })
      })
      .finally(() => {
        this.$store.dispatch('loader/loaderOff')
      })
      
      
    },
  },
  created () {
    this.$store.dispatch('loader/loaderOn')
    const venueId = this.currentVenueId
    
    this.getBookings();
    this.$store.dispatch('dataList/fetchbookingOptionsList', venueId);
    this.$store.dispatch('bookingStates/getBookingStatesArrayByArrayName', {arrayName: 'bookingConfirmationStatesAgent'})
    
    window.addEventListener('changeLanguage', function(e){
      this.$store.dispatch('loader/loaderOn')
      Promise.all([
        this.$store.dispatch('dataList/fetchbookingOptionsList', venueId),
      ]).then(()=>{this.$store.dispatch('loader/loaderOff')}, this);
    }.bind(this));
  },
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr{
          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            padding: 20px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }
          td.td-check{
            padding: 20px !important;
          }
      }
    }

    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check{
        padding: 0 15px !important;
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
.vs-tooltip { // this makes the tooltip appear above the popup.
  z-index: 99999 !important; 
}
</style>
